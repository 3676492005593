.infinite-scroll-component__outerdiv {
  padding: 0 !important;
}

#__next {
  padding: 0px;
}
.image-item {
  padding: 4px 0 4px 4px;
}

ul.react-multi-carousel-track li.react-multi-carousel-item {
  width: inherit!important;
}

@font-face{
  font-family: "modernist";
  src: url("../fonts/Sk-Modernist-Regular.woff2") format('woff2');
}
@font-face{
  font-family: "tstarmono";
  src: url("../fonts/tstarmono.woff2") format('woff2');
}
* {
  /*font-family: "T-Star Mono", mono;*/
  font-family: "modernist", mono;
  font-size: 1.02rem;
}
.herotag::before {
  content: '';
  font-family: 'consolas', monospace;
}
.herotag {
  text-decoration: none;
  font-family: 'consolas', monospace;
}

article.asd {
  border-radius: 15% 15% 16% 14% / 12% 12% 11% 10%;
}

.modal-content {
  border-radius: 15px !important;
}

.btn.pmd-btn-fab {
  width: 56px;
  min-width: 56px;
  height: 56px;
  padding: 0;
  border-radius: 50%;
}

body {
  height: 100vh;
  overflow:hidden
}


/* Bootstrap overrides
-------------------------------------------------- */
$body: #343332;
$body-bg: #343332;
$body-color: #16181f;
$border-color: #444;
$modal-content-bg: #fff;
$dropdown-menu-bg: #343332;
$headings-font-weight: 300 !important;
$primary: #16181f;
$light: #fafafa;
$navbar-brand-padding-y:0px;

/* Do not move the imports from here.
Override Bootstrap variables only above.
-------------------------------------------------- */

.text-dark {
  color: #16181f
}

.text-gray {
  color: #8f8f94
}

.text-gradient {
  background: linear-gradient(to right, #eb1484 0%, #c81cc5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Bootstrap
@import "./../../../node_modules/bootstrap/scss/bootstrap.scss";

// Dapp-utils
@import "./../../../node_modules/@elrondnetwork/dapp-utils/dist/dapp-utils.scss";

/* Base
-------------------------------------------------- */
html {
  min-height: 100%;
  font-size: 14px;
  background-color:#343332;
  color:#16181f;
}

.card-presale{
	background-color:red!important;
}

.bg-dark-input{
	background-color:#242526!important;
}
.accordion-body{
	background-color:#2b2d2e;
}
.accordion-button{
	border-radius:15px!important;
	background-color:#242526;
}
.accordion-collapse{
	background-color:#242526;
	border-radius:15px!important;
}

.accordion-button:not(.collapsed){
	background-color:#242526;
	color: #16181f;
}

.wrapper {
  min-height: 100vh;
}

.opacity-6 {
  opacity: 0.6;
}

.dapp-icon {
  padding: 5px;
  border-radius: 50%;
  background-color: #343332;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  &.icon-medium {
    width: 80px;
    height: 80px;
  }
}

.rounded{
	border-radius:10px!important;
}

.rounded-lg{
	border-radius:15px!important;
}

.rounded-top-lg{
  border-radius:15px 15px 0px 0px!important;
}

.rounded-sm{
	border-radius:5px!important;

}

.tokenSelector{
	border: 1px solid #ced4da!important;
	background-color:#343332!important;
}

.depositBtn{
	border: 1px solid #ced4da;
	border-left:0px;
	background-color:#f5f5f5;
}

.depositBtn:hover{
	color:$dark;
	background-color:#16181f;
}

.textInfoCustom2{
	font-size: 15rem;
	text-align: center;
    height:90vh;
    line-height: 90vh;
	color: #fcedd8;
	font-family: 'Niconne', cursive;
	font-weight: 700;
    text-shadow: 5px 5px 0px #eb452b,
                  10px 10px 0px #efa032,
                  15px 15px 0px #46b59b,
                  20px 20px 0px #017e7f,
                  25px 25px 0px #052939


}


.textInfoCustom {
  color: #16181f;
  text-shadow: 0 0 2px #16181f, 0 0 2px #0fa;
}

.card-home{
  background-color:transparent!important;
  border: 0px solid #16181f;
}

/* Navbar
-------------------------------------------------- */
.elrond-logo {
  height: 2em;
}

.dapp-name {
  line-height: 1;
  border-left: 1px solid $gray-400;
  padding-left: 3px;
  margin-left: 4px;
}

.dropdown-menu{
	background-color: #22262a;
	color: #16181f;
}

.dropdown-menu a{
	color: #16181f;
	text-decoration: none;
}
.dropdown-menu a:hover {
    color: #22262a;
}

/* Dashboard
-------------------------------------------------- */
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #16181f;
  margin: 0 7px;

  button {
    height: 40px;
    background-color: #16181f;
	font-weight: 700;

    &:hover {
      opacity: 0.9;
    }
  }
}

.transactions {
  .transaction-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  &.table {

    .transaction-hash .trim {
      @include media-breakpoint-down(xl) {
        max-width: none;
      }
      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }
      @include media-breakpoint-down(md) {
        max-width: 12rem;
      }
      @include media-breakpoint-down(sm) {
        max-width: 8rem;
      }
    }

    td, tr {
      padding: 0.65rem;
    }

    td {
      white-space: nowrap;
      vertical-align: middle;
    }

    tr:last-of-type {
      td {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }
}

.table-responsive{
  max-height:450px;
}


.text-invert{
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
/* Actions
-------------------------------------------------- */

.not-allwed {
  cursor: not-allowed;
}

/* Loader, Transaction Success/Fail
-------------------------------------------------- */
.page-state {
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .bg-blue .lds-ellipsis div {
    background: rgba(255, 255, 255, 0.4);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }
}
.state span._1b708{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
}


/* SLOT
---------------------------------------------- */

.tokenIconSelected{
	height:20px;
}
.tokenIconSelect{
	height:18px;
}

@media (max-width: 768px){
  .container-slot {
      max-width: 720px;
      padding-right: 0px;
      padding-left: 0px;
      margin-right: 0px;
      margin-left: 0px;
      border-radius:0px!important;
  }
}

.fullscreen{
    width: 100vw;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    max-height: none!important;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    background-color: black;
}

/* LAST BET
------------------------------------------------ */
.lastBet{
	height: 100px;

}

.lastBet-item{
	border: 1px solid #444;
	border-right: 6px solid $primary;
	width: 200px;
	min-width: 200px;
	min-height: 115px;
	max-height: 115px;
	padding: 4px;
	margin-right: 5px;
	margin-left: 5px;
	text-align: left !important;
	overflow: hidden;
}

.lastBet-item .tokenIcon{
	height:13px;
}

.lastBet-item .libelle{
	color: $gray-500;
	width:30px;
}

.lastBet-item .value{
	color: #16181f;
	margin-left:5px;
	margin-right:5px;
	float:right;
}

.animeBetItem {
  animation: showslow 1s ease-in;
}

@keyframes showslow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes leftshow {
  0% {
	opacity: 0;
    transform: translateX(5px);;
  }
  100% {
	 opacity: 1;
    transform: translateX(0px);;
  }
}


/* Login
-------------------------------------------------- */

.div-walletconnect .card{
  width: fit-content;
}

.div-ledger .btn,.div-walletconnect .btn {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15)!important;
  margin: 2px!important;
  border-color: #49494d!important;
  padding: 1.2rem;
  margin-bottom: 0.7rem!important;
  font-size: 1rem;
  width:100%;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 0.45rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.div-walletconnect #accessWalletBtn {
  padding-bottom: 11px!important;
  padding-top: 1px !important;
 }

.div-unlock .card-body div{
	padding: 20px;
	border-radius: 0.45rem;

}

.div-unlock .card,.div-walletconnect .card{
  border-radius: 1.45rem;
  border:solid 0px;

}

.div-unlock .card-body div{
	width: fit-content;

}

.div-unlock .btn:hover {
	border: 1px solid $primary!important;
	background-color: #2b2d2e!important;
}

.div-unlock .btn {
	box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15)!important;
	margin: 2px!important;
  border-color: #49494d!important;
  padding: 1.2rem;
  margin-bottom: 0.7rem!important;
  font-size: 1rem;
	width:100%;
	line-height: 1.5;
	border: 1px solid transparent;
  border-radius: 0.45rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	text-align: left;
}

.div-unlock .btn[data-testid="walletConnectLink"]::before{
  background-image:url(./../img/maiar-2d.png);
    display: inline-block;
    width: 23px;
    height: 23px;
    content: "";
    background-size: cover;
    position: absolute;
	right: 12%;
 }

 .div-unlock .btn[data-testid="ledgerLink"]::before{
  background-image:url(./../img/ledger.png);
    display: inline-block;
    width: 23px;
    height: 23px;
    content: "";
    background-size: cover;
    position: absolute;
	right: 12%;
 }

 .div-unlock .btn[data-testid="walletLink"]::before{
  background-image:url(./../img/egldoff-white.min.png);
    display: inline-block;
    width: 23px;
    height: 23px;
    content: "";
    background-size: cover;
    position: absolute;
	right: 12%;
 }


/* Footer
-------------------------------------------------- */
footer {
  div {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    display: inline-block;
    font-weight: 400;
    line-height: 1.155rem;

    a {
      color: #16181f;

      &:hover {
        color: #16181f;
      }

      #heart {
        height: 16px;
        width: 16px;

        path {
          fill: #c00;
        }
      }
    }
  }
}

input[tyme="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 16px;
  height: 16px;

  border: 2px solid #999;
  transition: 0.2s all linear;
  margin-right: 5px;

  position: relative;
  top: 4px;
}

input[tyme="radio"]:checked {
  border: 6px solid black;
}

// blur

.blurry .blurrer {
  height: 60px;
  top: -10px;
  left: -10px;
  right: -10px;
  position: absolute;
  overflow: hidden;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  z-index: -500;
  background-color: #2F4F4F;
  background-size: cover;
}
.blurry {
  background: none;
  color: white;
  border: none;
}
.blurry .navbar-brand {
  color: white;
  font-weight: 600;
  text-shadow: none;
}
.blurry .navbar-brand:hover {
  color: white;
}
.blurry .navbar-nav > .open > a,
.blurry .navbar-nav > .active > a {
  color: white;
}
.blurry .navbar-nav > .open > a:hover,
.blurry .navbar-nav > .active > a:hover {
  color: white;
}
.blurry .navbar-nav > li > a {
  color: white;
  font-weight: 500;
  text-shadow: none;
}
.blurry .navbar-nav > li > a:hover {
  color: white;
}

.my-modal .modal-content {
  background-color: transparent;
  border-color: transparent;
  margin-bottom:0vh;
}
